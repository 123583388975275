<template>
    <item-list-view
        :api="api"
        :headers="headers"
        title="Специальности"
        :disable-pagination="true"
        search-placeholder="Поиск по названию"
        :allowed-search="true"
        :allowed-create="false"
        :allowed-remove="false"
        update-route="Speciality"
    >
    </item-list-view>
</template>

<script>
import api from '../../services/specialities'
import ItemListView from '../../components/ItemListView'

export default {
  components: { ItemListView },
  data: () => ({
    api: api,
    headers: [
      { text: 'Специальность', value: 'name' },
      { text: '', value: 'action' }
    ]
  })
}
</script>
